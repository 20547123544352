import React, { useState } from "react";
import "antd/dist/antd.css";
import { Checkbox, Input, Form, Row } from "antd";

import "./glassForm.css";
import imagenes from "../imagenes/imagenes";
import { composeAuthHeader, requestAPI } from "../helpers/requestApi";

const glassForm = () => {
  const [registered, setRegistered] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <Row>
      <div className="container">
        <img src={imagenes.img1} alt="gigstack" />
        <Form className="formGlass" onFinish={async (vals) => {
          setRegistered(true)
          const header = composeAuthHeader(null, null, null)
          setLoading(true)
          try {
            const register = await requestAPI('https://us-central1-uplearning-co.cloudfunctions.net/landingEventRegister', { ...vals }, header, 'POST')
            console.log(register);
            setRegistered(true)
          } catch (e) {
            console.log(e);
            setLoading(false)
          }
        }}>
          <div hidden={registered} >


            <label style={{ color: "#f6f6f6" }}>Nombre</label>
            <Form.Item name="name" rules={[{ required: true, message: 'Por favor añade un nombre' }]}>
              <Input
                className="inputGlass"
                type="text"
                placeholder="Nombre"
              ></Input>
            </Form.Item>
            <label style={{ color: "#f6f6f6" }}>E-mail</label>
            <Form.Item name="email" rules={[{ required: true, message: 'Por favor añade tu correo' }]} style={{ margin: 0 }}>
              <Input
                className="inputGlass"
                type="email"
                placeholder="E-mail"
              ></Input>
            </Form.Item>
            <br />
            <Form.Item valuePropName="checked" rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
              },
            ]} name="terms">
              <Checkbox
                value="A"
                className="rounded"

                style={{ display: "flex", justifyContent: "center", color: 'white' }}
              >
                Acepto  <a href="https://worqlab.s3-us-west-1.amazonaws.com/legal/privacy.pdf" target="_blank" rel="noopener noreferrer">el aviso de privacidad</a>
              </Checkbox>
            </Form.Item>
            <br />
          </div>
          {!registered ? <button disabled={loading} className="boton" type="submit">
            <span>{loading ? 'Cargar' : 'Entrar'}</span>
          </button> : <p style={{ fontSize: '13px', color: 'white', textAlign: 'center' }}>¡Gracias por tu registro!</p>}
        </Form>
      </div>
    </Row>
  );
};

export default glassForm;


