import React from "react";
import { Col, Row, Typography } from "antd";
import "./inform.css";
import GlassForm from "./glassForm";

const { Text } = Typography;

const Inform = () => {
  return (
    <Row
      style={{
        background: "#110F24",
        minHeight: "100vh",
        height: "100%",
        minWidth: "100%",
      }}
    >
      <Col
        xs={24}
        lg={12}
        span={12}
        style={{
          paddingTop: "180px",
        }}
        className="spacePadding"
      >
        <Text
          className="letrasGig"
          style={{
            fontSize: "2.2rem",
            fontWeight: "lighter",
          }}
        >
          Acompáñanos en este <br /> nuevo camino
        </Text>
        <br />
        <br />
        <br />
        <Text className="letrasGig" style={{ fontSize: "1.5rem" }}>
          ¡Estamos muy emocionados por <br /> enseñarte todo lo nuevo! La nueva{" "}
          <br /> forma de <Text className="contratar">contratar</Text> llegó.
        </Text>
      </Col>
      <Col xs={24} lg={12} span={12} style={{ paddingTop: "50px" }}>
        <GlassForm />
      </Col>
    </Row>
  );
};

export default Inform;
