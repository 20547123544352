import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Button, Col, Row } from "antd";
import Header from "./components/header";
import Inform from "./components/inform";
import moment from 'moment'
function App() {
  const now = moment().valueOf();
  return (

    <>
      <Row >
        <Col xs={24} style={{ height: "100vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/6feMruV7pBQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Col>
        {now >= 1622765400000 && <Col xs={24} style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Row justify="center">
            <Col xs={24}>
              <img src="https://gigstack.s3.us-east-2.amazonaws.com/assets/logos/gigstack_name.png" alt="logo" />
            </Col>
            <Button onClick={() => window.open('https://app.gigstack.io')} style={{ backgroundColor: '#536DFF', borderRadius: '7px', color: 'white', border: 'none' }}>Entrar a la nueva plataforma</Button>
          </Row>
        </Col>}
      </Row>
    </>
  );
}

export default App;
