import React from "react";
import { Col, Row, Space, Typography } from "antd";
import "./header.css";


const { Text } = Typography;

const header = () => {
  return (
    <Row>
      <Col xs={24}>
        <Row style={{ height: "100vh" }}>
          <Col xs={24} lg={0} span={24} style={{ paddingTop: "10%" }}>
            <Space
              direction="vertical"
              align="center"
              style={{ width: "100%" }}
            >
              <Text className="gigstack">gigstack</Text>
              <br />
              <Text
                className="launchEvent"
                style={{ width: "100%", fontSize: "50px" }}
              >
                Launch Event
              </Text>
              <Text className="text3">Tu nueva forma de contratar</Text>
            </Space>
          </Col>
          <Col
            xs={0}
            lg={24}
            span={24}
            style={{ paddingTop: "10%", paddingLeft: "90px" }}
          >
            <Space direction="vertical" align="start" style={{ width: "100%" }}>
              <Text className="gigstack">gigstack</Text>
              <br />
              <Text className="launchEvent">Launch Event</Text>
              <Text className="text3">Tu nueva forma de contratar</Text>
            </Space>
          </Col>
          <Col xs={24} lg={0}>
            <Space
              direction="horizontal"
              align="center"
              style={{ width: "100%" }}
            >
              <br />
              <br />
              <br />
              <Text className="fecha1">03.06</Text>
              <Text className="hora">18:30</Text>
            </Space>
          </Col>
          <Col xs={0} lg={24}>
            <Space
              direction="horizontal"
              align="center"
              style={{ width: "100%", padding: "0px 0px 0px 70px" }}
            >
              <br />
              <br />
              <br />
              <Text className="fecha1">03.06</Text>
              <Text className="hora">18:30</Text>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default header;
